@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

html,
body {
  height: 100%;
}

* {
  font-family: 'Roboto';
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Montserrat"), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLight";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraLightItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Italic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-LightItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-MediumItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBoldItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Thin";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ThinItalic";
  src: local("Montserrat"), url(./assets/fonts/Montserrat/Montserrat-ThinItalic.ttf) format("truetype");
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-radius: 0 5px 5px 0 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  text-align: center;
  font-weight: 500 !important;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #fff;
  --mat-mdc-snack-bar-button-color: #fff;
  --mdc-snackbar-supporting-text-color: #000;
  border-radius: 5px 0 0 5px;
  margin: 0 !important;
  position: relative !important;
  left: 2%;
  top: 11px;
  border-spacing: revert;
  text-transform: capitalize;
}

.mat-mdc-snack-bar-container {
  &.success {
    border-left: 6px solid #4AB543;
  }
}

.mat-mdc-snack-bar-container {
  &.error {
    border-left: 6px solid #F44336;
  }
}

.mat-mdc-snack-bar-container {
  &.warning {
    border-left: 6px solid #EF984B;
  }
}

.mat-mdc-snack-bar-container {
  &.success ::before {
    position: relative;
    top: -2px;
    left: 9px;
    width: 20px;
    height: 20px;
    background-image: url('assets/images/succes_notify.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.mat-mdc-snack-bar-container {
  &.error ::before {
    position: relative;
    top: -2px;
    left: 9px;
    width: 20px;
    height: 20px;
    background-image: url('assets/images/error_notify.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.mat-mdc-snack-bar-container {
  &.warning ::before {
    position: relative;
    top: -2px;
    left: 9px;
    width: 20px;
    height: 20px;
    background-image: url('assets/images/warning_notify.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.form-field .mat-mdc-form-field-infix {
  border-right: 1px solid #E0E0E0 !important;
}

.form-field .mat-mdc-form-field-icon-suffix {
  padding: 12px !important;
}

.input-box {
  border: 1px solid #E0E0E0 !important;
  font-size: 19px !important;
}

.mat-mdc-paginator-container {
  .mat-mdc-paginator-range-label {
    display: none !important;
  }
}

.mat-mdc-paginator {
  background: none !important;
}

.common-toolbar {
  width: 100%;
  height: 9%;
  min-height: 60px;
}

.dashboard-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: -webkit-fill-available;
}

.add-btn {
  background: linear-gradient(179.06deg, rgba(239, 158, 0, 0.4) -37.46%, #EF9E00 99.19%) !important;
  padding: 5px 15px;
  color: #FFFFFF !important;
  box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.3490196078) inset;
  border: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}

.back-button {
  width: 97px;
  height: 38px;
  background: linear-gradient(179.06deg, rgba(239, 158, 0, 0.4) -37.46%, #EF9E00 99.19%) !important;
  padding: 5px 15px;
  color: #FFFFFF !important;
  box-shadow: 0px 4px 6px 0px rgba(255, 255, 255, 0.3490196078) inset;
  border: none;
  border-radius: 10px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(173, 167, 167, 0.5);
  z-index: 999;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  content: "";
  width: 75px;
  height: 75px;
  display: inline-block;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
  animation: spin 500ms infinite linear;
  -o-animation: spin 500ms infinite linear;
  -ms-animation: spin 500ms infinite linear;
  -webkit-animation: spin 500ms infinite linear;
  -moz-animation: spin 500ms infinite linear;
  border-left-color: transparent;
  border-right-color: transparent;
}

.assignee-spinner {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
  animation: spin 500ms infinite linear;
  -o-animation: spin 500ms infinite linear;
  -ms-animation: spin 500ms infinite linear;
  -webkit-animation: spin 500ms infinite linear;
  -moz-animation: spin 500ms infinite linear;
  border-left-color: transparent;
  border-right-color: transparent;
  position: relative;
  left: 37%;
}

.select .mat-mdc-select-placeholder {
  color: #000 !important;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// @media print {
//   :not(.printMe) {
//     visibility: hidden;
//   }
// }

// @media print {
//   .printMe {
//     visibility: visible;
//   }
// }